import { getCssClasses } from "../../../../utils/cssUtils";
import classes from "./TitleComponent.module.css";

interface Props {
  title: string;
  iconImage?: {
    icon: string;
    alt: string;
  };
  classNames?: string[];
}

export default function TitleComponent({
  classNames = [],
  title,
  iconImage,
}: Props) {
  return (
    <div className={classes.titleContainer}>
      {iconImage && (
        <img
          className={classes.titleIcon}
          src={iconImage.icon}
          alt={iconImage.alt}
        />
      )}
      <h2 className={getCssClasses([classes.titleText, ...classNames])}>
        {title}
      </h2>
    </div>
  );
}
