import { User } from "../models/User";
import { httpClient } from "./HttpClient";
import Logger from "./Logger";
import storageService from "./StorageService";
import { StorageTypes } from "../types/StorageTypes";
import { Authentication } from "../models/Authentication";
import ObservableService from "./ObservableService";

type formDataEntries = { [key: string]: string };

export enum AuthServiceEvents {
  AUTH_STATE_CHANGED = "auth-state-changed",
}

class AuthService extends ObservableService<AuthServiceEvents> {
  private user?: User;
  private logger = new Logger(AuthService.name);

  public getUser() {
    return this.user;
  }

  public getAuthToken() {
    return this.user?.authToken || "";
  }

  public async checkIfLogged(): Promise<boolean> {
    this.user = await storageService.getItem(StorageTypes.USER, null);

    if (this.user) {
      httpClient.setAuthentication(Authentication.of(this.user));
    }

    try {
      await this.validateLogin();
      this.notifyObservers(AuthServiceEvents.AUTH_STATE_CHANGED, true);

      return true;
    } catch (error) {
      this.logger.error("User is not logged", error);
      storageService.removeItem(StorageTypes.USER);
    }

    this.notifyObservers(AuthServiceEvents.AUTH_STATE_CHANGED, false);

    return false;
  }

  public async login(
    email: string,
    password: string,
    captchaToken: string
  ): Promise<User> {
    this.logger.info(
      `Trying loging with email ${email} and password ${password}`
    );

    this.user = await httpClient.post<User>("/auth/login-captcha", {
      email,
      password,
      captchaToken,
    });

    if (!this.user.authToken || !this.user.email) {
      throw new Error("Invalid credentials");
    }

    httpClient.setAuthentication(Authentication.of(this.user));
    storageService.setItem<User>(StorageTypes.USER, this.user);
    this.notifyObservers(AuthServiceEvents.AUTH_STATE_CHANGED, true);

    return this.user;
  }

  public async signUp(
    email: string,
    password: string,
    confirmPassword: string,
    captchaToken: string
  ): Promise<boolean> {
    this.logger.info(`Signing up ${email} and password ${password}`);

    this.user = await httpClient.post<User>("/auth/register-captcha", {
      email,
      password,
      confirmPassword,
      captchaToken,
    });

    // TODO: Login instantly
    /*
    if (!this.user.authToken) {
      throw new Error('Error signing in');
    }

    httpClient.setAuthToken(this.user.authToken);
    await storageService.setItem<User>(StorageTypes.USER, this.user);
    */
    return true;
  }

  public async recoverPassword(email: string): Promise<void> {
    this.logger.info(`Recovering password for email ${email}`);
    //WIP

    return Promise.resolve();
  }

  public async logout(): Promise<void> {
    await storageService.removeItem(StorageTypes.USER);
    this.notifyObservers(AuthServiceEvents.AUTH_STATE_CHANGED, false);
  }

  private buildFormData(entries: formDataEntries): FormData {
    const formData = new FormData();

    for (const key in entries) {
      formData.append(key, entries[key]);
    }

    return formData;
  }

  private validateLogin = (): Promise<void> => {
    return httpClient.post("/auth/validate-login", {});
  };
}

export default new AuthService();
