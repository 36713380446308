import i18n from "../../i18n/i18n";
import { Texts } from "../../i18n/Texts";

export default function NotFoundPage() {
  return (
    <div
      style={{
        fontSize: 24,
      }}
    >
      {i18n.translate(Texts.PAGE_NOT_FOUND)}
    </div>
  );
}
