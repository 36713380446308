import stepThreeImageOne from "../../../assets/wizard/step3_img1.png";
import ImageComponent from "../../../components/Common/UI/Image/ImageComponent";
import LinkComponent from "../../../components/Common/UI/Link/LinkComponent";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import { BASE_AFIP_URL } from "../../../constants/urls";

interface Props {
  taxId: string;
}

export default function ThreeDigitalCertificatePage({ taxId }: Props) {
  return (
    <>
      <TitleComponent title="3. Certificados digitales" />
      <TextComponent>
        Agregue la posibilidad de administrar certificados digitales mediante el
        siguiente link:
      </TextComponent>
      <LinkComponent
        text="Certificados digitales"
        to={`${BASE_AFIP_URL}/ClaveFiscal/AdminRel/relationAdd.aspx?representado=${taxId}&representante=${taxId}&servicename=web://arfe_certificado`}
      />
      <ImageComponent src={stepThreeImageOne} alt="Inicio de sesión en AFIP" />
    </>
  );
}
