class StorageService {
  setItem<T>(key: string, obj: T) {
    return localStorage.setItem(key, JSON.stringify(obj));
  }

  getItem<T>(key: string, ifNull: any): T {
    let result = localStorage.getItem(key);
    if (result == null) {
      return ifNull;
    }
    return JSON.parse(result);
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }
}

export default new StorageService();
