import {useState, Dispatch, SetStateAction} from 'react';
import {ValidationGroup} from './types/ValidationGroup';

/**
 * @returns [setValidationGroup, valid]
 *
 * setValidationGroup: a setter that adds a validator to the validation group.
 * This function doesn't meant to be used, just to be passed to the
 * useValidatedState hook as a parameter
 *
 * valid: a function that when executed returns a boolean indicating whether
 * the form is valid or not
 */
export default function useValidationGroup(): [
  Dispatch<SetStateAction<ValidationGroup>>,
  (callback?: () => void) => boolean,
] {
  const [validationGroup, setValidationGroup] = useState<ValidationGroup>([]);

  /**
   * Simple function that returns a boolean indicating if the form is
   * correctly validated
   *
   * @param callback optional callback if it's a valid form
   */
  function valid(callback?: () => void): boolean {
    const result = validationGroup.filter((validation) => {
      return validation.current().length > 0;
    });

    const validated = result.length === 0;

    if (validated && callback) {
      callback();
    }

    return validated;
  }

  return [setValidationGroup, valid];
}
