import { Currency } from "../types/Currency";

export class Settings {
  constructor(
    public address: string,
    public nextInvoiceNumber: number,
    public defaultConcept: string,
    public taxId: string,
    public defaultAmount: number,
    public defaultCurrency: Currency,
    public name: string,
    public defaultCustomerTaxId: string
  ) {
    this.address = address;
    this.nextInvoiceNumber = nextInvoiceNumber;
    this.defaultConcept = defaultConcept;
    this.taxId = taxId;
    this.defaultAmount = defaultAmount;
    this.defaultCurrency = defaultCurrency;
    this.name = name;
    this.defaultCustomerTaxId = defaultCustomerTaxId;
  }

  public static getDefaultSettings() {
    return new Settings("", 1, "", "", 0.0, Currency.ARS, "", "");
  }

  public static testSettings() {
    return new Settings(
      "123 55",
      Math.round(Math.random() * 5),
      "services",
      "1s120a",
      +(Math.random() * 100).toFixed(2),
      Currency.ARS,
      "Some name",
      "123ls"
    );
  }
}
