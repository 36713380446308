import { useState } from "react";
import ButtonLinkComponent from "../../../components/Common/UI/ButtonLink/ButtonLinkComponent";
import Spinner from "../../../components/Common/UI/Spinner";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import UIService from "../../../services/UIService";
import userService from "../../../services/UserService";

export default function FourDownloadCertificatePage() {
  const [loading, setLoading] = useState<boolean>();

  const downloadCertificate = async () => {
    setLoading(true);

    try {
      const response = await userService.getCsrCertificate();
      const link = document.createElement("a");
      link.href = response.location;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    } catch (error) {
      UIService.toastError(
        "No se pudo descargar el certificado. Por favor, inténtatlo mas tarde"
      );
    }

    setLoading(false);
  };

  return (
    <>
      <TitleComponent title="4. Descargar certificado" />{" "}
      <TextComponent inline>
        El siguiente pedido de certificado es generado por nosotros para poder
        operar con factura electronica. Por favor descarguelo desde{" "}
      </TextComponent>
      <ButtonLinkComponent onClick={downloadCertificate} text="aquí" />
      {loading && <Spinner />}
    </>
  );
}
