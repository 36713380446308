export enum AppRoutes {
  CHANGE_PASSWORD = "/user/change-password",
}

export enum LoginRoutes {
  LOGIN = "/auth/login",
  SIGNUP = "/auth/signup",
  RECOVER_PASSWORD = "/auth/recover-password",
}

export enum WizardRoutes {
  ONE_LOGIN,
  TWO_RELATION_ADMIN,
  THREE_DIGITAL_CERT,
  FOUR_DOWNLOAD_CERT,
  FIVE_ADD_CERT,
  SIX_UPLOAD_CERT,
  SEVEN_ADD_SALE_POINT,
  EIGHT_LINK_CERT,
  NINE_VERIFICATION,
  TEN_STEPS_FINISHED,
}
