import classes from "./ButtonLinkComponent.module.css";

interface ButtonLinkProps {
  onClick: () => void;
  text: string;
}

export default function ButtonLinkComponent(props: ButtonLinkProps) {
  return (
    <button className={classes.button} onClick={props.onClick}>
      {props.text}
    </button>
  );
}
