import { MutableRefObject } from "react";
import i18n from "../i18n/i18n";
import { Texts } from "../i18n/Texts";

//Input Validators
export function isRequired(field: string): (value: string) => string {
  const message = i18n.translate(Texts.VALIDATION_IS_REQUIRED, [field]);

  return (value) => (value.trim().length === 0 ? message! : "");
}

export function minLength(
  field: string,
  minLength: number = 3
): (value: string) => string {
  const message = i18n.translate(Texts.VALIDATION_MIN_LENGTH, [
    field,
    minLength,
  ]);

  return (value) => (value.trim().length < minLength ? message! : "");
}

export function maxLength(
  field: string,
  maxLength: number = 3
): (value: string) => string {
  const message = i18n.translate(Texts.VALIDATION_MAX_LENGTH, [
    field,
    maxLength,
  ]);

  return (value) => (value.trim().length < maxLength ? message! : "");
}

export function strongPassword(
  field: string = i18n.translate(Texts.PASSWORD).toLowerCase()
): (value: string) => string {
  const message = i18n.translate(Texts.VALIDATION_STRONG_PASSWORD, [field]);

  return (value) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value.trim())
      ? ""
      : message;
}

export function isGreaterThanToday(
  field: string = i18n.translate(Texts.DATE)
): (value: Date) => string {
  const message = i18n.translate(Texts.VALIDATION_DATE_GREATER_TODAY, [field]);

  return (value) => (value.getTime() > Date.now() ? "" : message);
}

export function isGreaterThan(
  dateRef: MutableRefObject<Date>,
  field: string = i18n.translate(Texts.DATE),
  secondField: string = i18n.translate(Texts.DATE)
): (value: Date) => string {
  const message = i18n.translate(Texts.VALIDATION_GREATER_THAN_DATE, [
    field,
    secondField,
  ]);

  return (value) => {
    return value.getTime() > dateRef.current.getTime() ? "" : message;
  };
}

export function isEqualTo(
  passwordRef: MutableRefObject<string>,
  fields: string = i18n.translate(Texts.PASSWORDS).toLowerCase()
): (value: string) => string {
  const message = i18n.translate(Texts.VALIDATION_PASSWORD_MATCH, [fields]);
  return (value) => {
    return value === passwordRef.current ? "" : message;
  };
}

export function isEmail(
  field: string = i18n.translate(Texts.EMAIL).toLowerCase()
): (value: string) => string {
  const message = i18n.translate(Texts.VALIDATION_IS_EMAIL, [field]);

  return (value) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
      ? ""
      : message;
  };
}

//Compound validators:

export const simpleValidation = (field: string = "Value") => [
  isRequired(field),
  minLength(field),
];

//Pristine validators
export const afterFirstChar = (value: string) => value.length > 0;

export const afterChangingDate = (value: Date) =>
  Math.abs(Date.now() - value.getTime()) > 1000;
