export default class Logger {
  private logEnabled;

  constructor(private prefix: string) {
    this.logEnabled = process.env.NODE_ENV === "development";
  }

  info(message: string, ...args: any[]) {
    if (this.logEnabled) {
      console.log(`[INFO] [${this.prefix}]: ${message}`, ...args);
    }
  }

  warn(message: string, ...args: any[]) {
    if (this.logEnabled) {
      console.log(`[WARN] [${this.prefix}]: ${message}`, ...args);
    }
  }

  error(message: string, error: Error) {
    if (this.logEnabled) {
      console.log(`[ERROR] [${this.prefix}]: ${message}`, error);
    }
  }

  crash(message: string, error: Error) {
    if (this.logEnabled) {
      console.error(`[CRASH] [${this.prefix}]: ${message}`, error);
    }
  }
}
