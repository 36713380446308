import AuthFormComponent from "../AuthFormComponent";
import useValidatedState from "../../../hooks/useValidatedState";
import useValidationGroup from "../../../hooks/useValidationGroup";
import { afterFirstChar, isEmail } from "../../../utils/validators";
import InputComponent from "../../Common/Form/Input/InputComponent";
import userIcon from "../../../assets/login/ic_user.png";
import uiService from "../../../services/UIService";
import i18n from "../../../i18n/i18n";
import { Texts } from "../../../i18n/Texts";

export default function RecoverPasswordComponent() {
  const [validationGroup, formValid] = useValidationGroup();
  const [email, setEmail, emailError] = useValidatedState<string>(
    "",
    [isEmail()],
    afterFirstChar,
    validationGroup
  );

  const onSubmitHandler = (captchaToken: string) => {
    if (!formValid()) {
      return;
    }

    //TODO: Implement recover password
    try {
    } catch (error) {
      console.log(error);
      uiService.toastError(
        i18n.translate(Texts.ERROR_OCCURRED_TRY_AGAIN_LATER)
      );
    }
  };

  return (
    <AuthFormComponent
      onSubmit={onSubmitHandler}
      submitText={i18n.translate(Texts.SEND)}
    >
      <InputComponent
        autoFocus
        value={email}
        setValue={setEmail}
        icon={userIcon}
        error={emailError}
        autoCapitalize="false"
        placeholder={i18n.translate(Texts.EMAIL)}
      />
    </AuthFormComponent>
  );
}
