import React from "react";
import LinkComponent from "../../../components/Common/UI/Link/LinkComponent";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import { BASE_AFIP_URL } from "../../../constants/urls";
import stepTwoImageOne from "../../../assets/wizard/step2_img1.png";
import ImageComponent from "../../../components/Common/UI/Image/ImageComponent";

export default function TwoRelationshipManagerPage() {
  return (
    <>
      <TitleComponent title="2. Administrador de relaciones" />
      <TextComponent>
        Ingrese al Administrador de Relaciones y seleccione su CUIT
      </TextComponent>
      <LinkComponent
        text="Administrador de relaciones"
        to={`${BASE_AFIP_URL}/claveFiscal/adminRel/selectAuthority.aspx`}
      />
      <ImageComponent src={stepTwoImageOne} alt="Inicio de sesión en AFIP" />
    </>
  );
}
