import { Texts } from "./Texts";
import { en, es } from "./locales";
import { getUserLocales } from "get-user-locale";
import Logger from "../services/Logger";

class i18n {
  logger = new Logger(this.constructor.name);
  locale: { [key in Texts]: string };
  availableLocales: { [key: string]: { [key in Texts]: string } } = {
    en,
    es,
  };

  constructor() {
    // TODO: Set to en
    this.locale = es;

    // Each userLocale is something like 'en' or 'en_US'
    const userLocales = getUserLocales();

    // This is currently not making difference between [es-AR, es-ES] or [en-US, en-GB]
    for (const userLocale of userLocales) {
      //Keep the first part. en-US => en | es-AR => es
      const currLocale = userLocale.substr(0, 2);

      if (this.availableLocales[currLocale]) {
        this.locale = this.availableLocales[currLocale];
        this.logger.info(`Setting language to [${currLocale}]`);
        break;
      }
    }
  }

  translate(key: Texts, extraData: (string | number)[] = []): string {
    let text = this.locale[key] || null;

    if (text == null) {
      text = en[key] || "< missed_string_entry >";
    }

    extraData.forEach((item, index) => {
      text = text!.replace("${" + index + "}", item.toString());
    });

    return text;
  }
}

export default new i18n();
