import React, { SetStateAction, useRef } from "react";
import classes from "./SixUploadCertificatePage.module.css";
import ButtonComponent from "../../../components/Common/Form/ButtonComponent";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import UserService from "../../../services/UserService";
import Logger from "../../../services/Logger";
import Spinner from "../../../components/Common/UI/Spinner";
import UIService from "../../../services/UIService";

interface Props {
  setFile: React.Dispatch<SetStateAction<File | null>>;
  file: File | null;
  onFileUpload: () => void;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  loading: boolean;
}

const logger = new Logger(SixUploadCertificatePage.name);

export default function SixUploadCertificatePage(props: Props) {
  const { onFileUpload, loading, setLoading, file, setFile } = props;

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onSelectFileHandler = (e: any) => {
    setFile(e.target.files![0]);
    console.log(e.target.files[0]);
  };

  const uploadCertificate = async () => {
    if (!file) {
      return;
    }

    setLoading(true);

    try {
      await UserService.uploadCsrCertificate(file);
      UIService.toastSuccess("El certificado se subió exitosamente");
      onFileUpload();
    } catch (error) {
      logger.error("Could not upload certificate", error);
      UIService.toastError(
        "No se pudo subir certificado, el usuario esta configurado en Argentina?"
      );
    }

    setLoading(false);
  };

  const onClickSelectFileBtn = () => {
    if (file) {
      setFile(null);
    } else {
      hiddenFileInput.current!.click();
    }
  };

  let selectFileClasses = [classes.button];
  if (file) {
    selectFileClasses.push(classes.cancelButton);
  }

  return (
    <>
      <TitleComponent title="6. Subir certificado" />
      <TextComponent inline>
        El certificado descargado en el punto anterior. Súbalo aquí
      </TextComponent>
      <input
        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
        type="file"
        onChange={onSelectFileHandler}
        ref={hiddenFileInput}
      />
      {loading ? (
        <Spinner />
      ) : (
        <div className={classes.fileInputContainer}>
          <TextComponent>
            {!file
              ? "Debe subir un certificado"
              : "El certificado se encuentra subido"}
          </TextComponent>
          <div className={classes.buttonsContainer}>
            <ButtonComponent
              classNames={selectFileClasses}
              onClick={onClickSelectFileBtn}
              text={!file ? "Seleccionar" : "Cancelar"}
            />
            <ButtonComponent
              classNames={[classes.button]}
              onClick={uploadCertificate}
              text="Subir"
              disabled={!file}
            />
          </div>
        </div>
      )}
    </>
  );
}
