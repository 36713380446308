import { TimelineItemStatus } from "../types/TimelineItemStatus";

export const getCssClasses = (classNames: string[]) => {
  return classNames.join(" ");
};

const timelineItemStatus = {
  [TimelineItemStatus.DONE]: "done",
  [TimelineItemStatus.IN_PROCESS]: "inProcess",
  [TimelineItemStatus.PENDING]: "pending",
};

export const getClassNameByTimelineItemStatus = (
  status: TimelineItemStatus
): string => {
  return timelineItemStatus[status];
};
