import {AxiosResponse} from 'axios';

export default class InvalidResponseError extends Error {
  response: AxiosResponse;
  constructor(message: string, response: AxiosResponse) {
    super(message);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = InvalidResponseError.name; // stack traces display correctly now
    this.response = response;
  }
}
