import {User} from './User';

export class Authentication {
  email: string;
  authToken: string;

  constructor(email: string, authToken: string) {
    this.email = email;
    this.authToken = authToken;
  }

  public static of(user: User) {
    return new Authentication(user.email, user.authToken);
  }
}
