import React from "react";
import LinkComponent from "../../../components/Common/UI/Link/LinkComponent";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import { BASE_AFIP_URL } from "../../../constants/urls";
import stepEightImageOne from "../../../assets/wizard/step8_img1.png";
import stepEightImageTwo from "../../../assets/wizard/step8_img2.png";
import stepEightImageThree from "../../../assets/wizard/step8_img3.png";
import ImageComponent from "../../../components/Common/UI/Image/ImageComponent";

interface Props {
  taxId: string;
}
export default function EightLinkCertificatePage({ taxId }: Props) {
  return (
    <>
      <TitleComponent title="8. Punto de venta" />
      <TextComponent inline>Ingrese al </TextComponent>
      <LinkComponent
        text="Administrador de relaciones"
        to={`${BASE_AFIP_URL}/ClaveFiscal/AdminRel/relationAdd.aspx?representado=${taxId}&servicename=ws://wsfe`}
      />
      <ImageComponent src={stepEightImageOne} alt="Inicio de sesión en AFIP" />
      <TextComponent>Haga click en el segundo buscar</TextComponent>
      <ImageComponent src={stepEightImageTwo} alt="Inicio de sesión en AFIP" />
      <TextComponent>
        Seleccione mynvoice y haga click en confirmar
      </TextComponent>
      <ImageComponent
        src={stepEightImageThree}
        alt="Inicio de sesión en AFIP"
      />
      <TextComponent>
        Por último vuelva a hacer click en confirmar
      </TextComponent>
    </>
  );
}
