/* eslint-disable no-template-curly-in-string */
import { Texts } from "../Texts";

export const en: { [key in Texts]: string } = {
  /* Authentication */
  [Texts.LOG_IN]: "Log in",
  [Texts.CREATE_ACCOUNT]: "Create account",
  [Texts.DID_YOU_FORGET_PASSWORD]: "Did you forget your password?",
  [Texts.REGISTER]: "Register",
  [Texts.PASSWORD]: "Password",
  [Texts.PASSWORDS]: "Passwords",
  [Texts.CONFIRM_PASSWORD]: "Confirm password",
  [Texts.ADD_EMAIL_TO_RECEIVE_CODE]:
    "Enter an email address to receive a new verification code",
  [Texts.EMAIL]: "E-mail",
  [Texts.SEND]: "Send",
  [Texts.LOGIN]: "Log in",
  [Texts.LOGOUT]: "Log out",
  [Texts.ACCOUNT_CREATED_SUCCESSFULLY]: "Account created successfully",
  [Texts.COULD_NOT_CREATE_ACCOUNT]: "Could not create account. E-mail in use?",
  [Texts.DO_YOU_ALREADY_OWN_AN_ACCOUNT]: "Do you already own an account?",
  [Texts.COMPLETE_CAPTCHA_TO_CONTINUE]: "Check the captcha to continue",
  [Texts.NO_ACCOUNT_YET]: "You don't have an account?",

  /* Wizard */
  [Texts.CONFIGURE_STEPS]: "Configuration steps",
  [Texts.PREVIOUS]: "Previous",
  [Texts.NEXT]: "Next",
  [Texts.FINISH]: "Finish",

  /* Main App */
  [Texts.MY_INVOICES]: "My invoices",
  [Texts.SETTINGS]: "Settings",
  [Texts.GO_BACK]: "Go back",
  [Texts.INVOICE]: "Invoice",
  [Texts.INVOICE_NO]: "Invoice #${0}",
  [Texts.NO_INVOICES_FOR_NOW]: "No invoices for now",
  [Texts.OPTIONS]: "Options",
  [Texts.CONFIRM]: "Confirm",
  [Texts.CONCEPT]: "Concept",
  [Texts.DATE]: "Date",
  [Texts.DESCRIPTION]: "Description",
  [Texts.VAT_CONDITION]: "VAT Condition",
  [Texts.INVOICE_NO_SHORT]: "INV #${0}",
  [Texts.GENERATE_INVOICE]: "Generate invoice",
  [Texts.AMOUNT]: "Amount",
  [Texts.TAX_ID]: "Tax Id",
  [Texts.CUSTOMER]: "Customer",
  [Texts.CUSTOMER_TAX_ID]: "Customer Tax Id",
  [Texts.BUSINESS_NAME]: "Business name",
  [Texts.ADDRESS]: "Address",
  [Texts.NEXT_INVOICE_NUMBER]: "Next invoice number",
  [Texts.CURRENCY]: "Currency",
  [Texts.CHANGE_PASSWORD]: "Change password",
  [Texts.OLD_PASSWORD]: "Old password",
  [Texts.NEW_PASSWORD]: "New password",
  [Texts.CONFIRM_PASSWORD]: "Confirm password",
  [Texts.PAGE_NOT_FOUND]: "Page not found",

  /* Form Validators */
  [Texts.VALIDATION_IS_REQUIRED]: "The field ${0} is required",
  [Texts.VALIDATION_MIN_LENGTH]:
    "The field ${0} must be at least ${1} characters long",
  [Texts.VALIDATION_MAX_LENGTH]:
    "The field ${0} cannot exceed ${1} characters long",
  [Texts.VALIDATION_STRONG_PASSWORD]:
    "The field ${0} must contain one number, one uppercase letter and at least at least 6 characters long",
  [Texts.VALIDATION_DATE_GREATER_TODAY]: "The field ${0} must be after today",
  [Texts.VALIDATION_GREATER_THAN_DATE]: "The field ${0} must be after ${1}",
  [Texts.VALIDATION_PASSWORD_MATCH]: "The passwords must match",
  [Texts.VALIDATION_IS_EMAIL]: "The field ${0} must be a valid email",

  /* Form Placeholders */
  [Texts.PLACEHOLDER_DEFAULT_CONCEPT]: "Development services",
  [Texts.PLACEHOLDER_EMAIL]: "name@company.com",
  [Texts.PLACEHOLDER_BUSINESS_NAME]: "Akme Corporation",
  [Texts.PLACEHOLDER_ADDRESS]: "20 W 34th St",
  [Texts.PLACEHOLDER_TAX_ID]: "DQ123456C",

  /* Alerts, errors */
  [Texts.ERROR]: "Error",
  [Texts.ERROR_OCCURRED_LOGIN]: "An error occurred when trying to log in",
  [Texts.ERROR_OCCURRED_TRY_AGAIN_LATER]:
    "An error occurred. Please, try again later",
  [Texts.ERROR_OCCURRED_UPDATING_SETTINGS]:
    "An error occurred. Could not update settings",
  [Texts.SETTINGS_REQUIRED]: "Settings required",
  [Texts.SET_SETTINGS_BEFORE_CREATING_INVOICE]:
    "You have to configure your settings before you are able to create a new invoice",
  [Texts.INVALID_CREDENTIALS]: "Invalid credentials",
  [Texts.ERROR_PDF_NOT_VALID]: "PDF is not valid",
  [Texts.ERROR_SOMETHING_HAPPENED]: "Something happened",
  [Texts.TRY_AGAIN]: "Try again",

  /* Image alts */
  [Texts.MYNVOICE_LOGO]: "Mynvoice Logo",
};
