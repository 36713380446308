import authLogo from "../../../assets/login/logo_login.png";
import i18n from "../../../i18n/i18n";
import { Texts } from "../../../i18n/Texts";
import classes from "./MynvoiceLogoComponent.module.css";

interface Props {}

export default function MynvoiceLogoComponent(props: Props) {
  return (
    <img
      className={classes.mynvoiceLogo}
      src={authLogo}
      alt={i18n.translate(Texts.MYNVOICE_LOGO)}
    />
  );
}
