import { LoginRoutes } from "../../constants/routes";
import { Link } from "react-router-dom";
import classes from "./AuthOptionsComponent.module.css";
import i18n from "../../i18n/i18n";
import { Texts } from "../../i18n/Texts";

interface Props {
  pathname: string;
}

export default function AuthOptions({ pathname }: Props) {
  return (
    <div className={classes.authOptions}>
      {getAuthNavigationLinks(pathname as LoginRoutes)}
    </div>
  );
}

const getAuthNavigationLinks = (route: LoginRoutes): JSX.Element => {
  switch (route) {
    case LoginRoutes.SIGNUP:
      return (
        <>
          <Login />
          <RecoverPassword />
        </>
      );
    case LoginRoutes.RECOVER_PASSWORD:
      return (
        <>
          <Login />
          <CreateNewAccount />
        </>
      );
    case LoginRoutes.LOGIN:
    default:
      return (
        <>
          <CreateNewAccount />
          <RecoverPassword />
        </>
      );
  }
};

const RecoverPassword = () => (
  <Link className={classes.optionText} to={LoginRoutes.RECOVER_PASSWORD}>
    {i18n.translate(Texts.DID_YOU_FORGET_PASSWORD)}
  </Link>
);

const CreateNewAccount = () => (
  <Link className={classes.optionText} to={LoginRoutes.SIGNUP}>
    {i18n.translate(Texts.NO_ACCOUNT_YET)}
  </Link>
);

const Login = () => (
  <Link className={classes.optionText} to={LoginRoutes.LOGIN}>
    {i18n.translate(Texts.DO_YOU_ALREADY_OWN_AN_ACCOUNT)}
  </Link>
);
