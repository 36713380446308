/* eslint-disable no-template-curly-in-string */
import { Texts } from "../Texts";

export const es: { [key in Texts]: string } = {
  /* Authentication */
  [Texts.LOG_IN]: "Iniciar sesión",
  [Texts.CREATE_ACCOUNT]: "Crear cuenta",
  [Texts.DID_YOU_FORGET_PASSWORD]: "¿Se te olvidó tu contraseña?",
  [Texts.REGISTER]: "Registrarse",
  [Texts.PASSWORD]: "Contraseña",
  [Texts.PASSWORDS]: "Contraseñas",
  [Texts.CONFIRM_PASSWORD]: "Confirmar contraseña",
  [Texts.ADD_EMAIL_TO_RECEIVE_CODE]:
    "Ingresa una dirección de correo electrónico para recibir un nuevo código de verificación.",
  [Texts.EMAIL]: "Correo electrónico",
  [Texts.SEND]: "Enviar",
  [Texts.LOGIN]: "Ingresar",
  [Texts.LOGOUT]: "Cerrar sesión",
  [Texts.ACCOUNT_CREATED_SUCCESSFULLY]: "Cuenta creada exitosamente",
  [Texts.COULD_NOT_CREATE_ACCOUNT]: "No se pudo crear cuenta. ¿Correo en uso?",
  [Texts.DO_YOU_ALREADY_OWN_AN_ACCOUNT]: "¿Ya tienes cuenta",
  [Texts.COMPLETE_CAPTCHA_TO_CONTINUE]: "Completa el captcha para continuar",
  [Texts.NO_ACCOUNT_YET]: "¿No tienes cuenta?",

  /* Wizard */
  [Texts.CONFIGURE_STEPS]: "Pasos para configurar",
  [Texts.PREVIOUS]: "Anterior",
  [Texts.NEXT]: "Siguiente",
  [Texts.FINISH]: "Finalizar",

  /* Main App */
  [Texts.MY_INVOICES]: "Mis facturas",
  [Texts.SETTINGS]: "Configuración",
  [Texts.GO_BACK]: "Volver",
  [Texts.INVOICE]: "Factura",
  [Texts.INVOICE_NO]: "Factura #${0}",
  [Texts.NO_INVOICES_FOR_NOW]: "Sin facturas por el momento",
  [Texts.OPTIONS]: "Opciones",
  [Texts.CONFIRM]: "Confirmar",
  [Texts.CONCEPT]: "Concepto",
  [Texts.DATE]: "Fecha",
  [Texts.DESCRIPTION]: "Descripción",
  [Texts.VAT_CONDITION]: "Condición frente al IVA",
  [Texts.INVOICE_NO_SHORT]: "FAC #${0}",
  [Texts.GENERATE_INVOICE]: "Generar factura",
  [Texts.AMOUNT]: "Importe",
  [Texts.TAX_ID]: "Cuit",
  [Texts.CUSTOMER]: "Cliente",
  [Texts.CUSTOMER_TAX_ID]: "Cuit del cliente",
  [Texts.BUSINESS_NAME]: "Razón Social",
  [Texts.ADDRESS]: "Direción",
  [Texts.NEXT_INVOICE_NUMBER]: "Siguiente número de factura",
  [Texts.CURRENCY]: "Moneda",
  [Texts.CHANGE_PASSWORD]: "Cambiar contraseña",
  [Texts.OLD_PASSWORD]: "Contraseña antigua",
  [Texts.NEW_PASSWORD]: "Nueva contraseña",
  [Texts.CONFIRM_PASSWORD]: "Confirmar contraseña",
  [Texts.PAGE_NOT_FOUND]: "Pagina no encontrada",

  /* Form Placeholders */
  [Texts.PLACEHOLDER_DEFAULT_CONCEPT]: "Servicios de desarrollo",
  [Texts.PLACEHOLDER_EMAIL]: "nombre@empresa.com",
  [Texts.PLACEHOLDER_BUSINESS_NAME]: "Arcos Dorados S.A",
  [Texts.PLACEHOLDER_ADDRESS]: "Av. Rivadavia no. 123",
  [Texts.PLACEHOLDER_TAX_ID]: "1234567890",

  /* Form Validators */
  [Texts.VALIDATION_IS_REQUIRED]: "El campo ${0} es obligatorio",
  [Texts.VALIDATION_MIN_LENGTH]:
    "El campo ${0} debe tener al menos ${1} caracteres",
  [Texts.VALIDATION_MAX_LENGTH]:
    "El campo ${0} no debe exceder los ${1} caracteres",
  [Texts.VALIDATION_STRONG_PASSWORD]:
    "El campo ${0} debe contener un número, una letra en mayúscula y tener al menos 6 caracteres de longitud",
  [Texts.VALIDATION_DATE_GREATER_TODAY]:
    "El campo ${0} debe ser posterior a hoy",
  [Texts.VALIDATION_GREATER_THAN_DATE]:
    "El campo ${0} debe ser posterior a ${1}",
  [Texts.VALIDATION_PASSWORD_MATCH]: "Las contraseñas deben coincidir",
  [Texts.VALIDATION_IS_EMAIL]:
    "El campo ${0} debe ser un correo electrónico válido",

  /* Alerts, errors */
  [Texts.ERROR]: "Error",
  [Texts.ERROR_OCCURRED_LOGIN]: "Ocurrió un problema iniciando sesión",
  [Texts.ERROR_OCCURRED_TRY_AGAIN_LATER]:
    "Ocurrio un error, inténtalo mas tarde",
  [Texts.ERROR_OCCURRED_UPDATING_SETTINGS]:
    "No se pudo actualizar la configuración",
  [Texts.SETTINGS_REQUIRED]: "Configuración requerida",
  [Texts.SET_SETTINGS_BEFORE_CREATING_INVOICE]:
    "Tienes que crear tu configuración antes de poder crear una nueva factura",
  [Texts.INVALID_CREDENTIALS]: "Credenciales inválidas",
  [Texts.ERROR_PDF_NOT_VALID]: "Este PDF no es válido",
  [Texts.ERROR_SOMETHING_HAPPENED]: "Hubo un problema",
  [Texts.TRY_AGAIN]: "Reintentar",

  /* Image alts */
  [Texts.MYNVOICE_LOGO]: "Logo de Mynvoice",
};
