import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";

export default function ConfigurationCompletedPage() {
  return (
    <>
      <TitleComponent title="Completado" />
      <TextComponent>
        Has completado todo los pasos, ya puedes empezar a crear facturas
      </TextComponent>
    </>
  );
}
