import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import WizardPage from "../pages/Wizard/WizardPage";

export default function AppNavigation() {
  useEffect(() => {}, []);

  return (
    <Switch>
      {/* Add authenticated routes here */}
      <Route path="/">
        <WizardPage />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
