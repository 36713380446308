import { getCssClasses } from "../../../utils/cssUtils";
import classes from "./ErrorMessageComponent.module.css";

interface Props {
  text: string;
}

export default function ErrorMessageComponent(props: Props) {
  let cssClasses = classes.text;

  if (props.text) {
    cssClasses = getCssClasses([classes.text, classes.error]);
  }

  return <p className={cssClasses}>{props.text || "no error"}</p>;
}
