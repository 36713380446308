import LinkComponent from "../../../components/Common/UI/Link/LinkComponent";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import { BASE_AFIP_URL } from "../../../constants/urls";
import stepFiveImageOne from "../../../assets/wizard/step5_img1.png";
import stepFiveImageTwo from "../../../assets/wizard/step5_img2.png";
import ImageComponent from "../../../components/Common/UI/Image/ImageComponent";

export default function FiveAddCertificatePage() {
  return (
    <>
      <TitleComponent title="5. Agregar certificado" />
      <TextComponent>
        Agregue el certificado que descargó en el punto anterior en el siguiente
        link:
      </TextComponent>
      <LinkComponent
        text="Agregar certificado"
        to={`${BASE_AFIP_URL}/clavefiscal/adminrel/verCertificado.aspx`}
      />
      <TextComponent>Haga click en agregar alias:</TextComponent>
      <ImageComponent src={stepFiveImageOne} alt="Inicio de sesión en AFIP" />
      <TextComponent>
        En alias escriba "mynvoice", elija el archivo que descargó y haga click
        en “Agregar alias”
      </TextComponent>
      <ImageComponent src={stepFiveImageTwo} alt="Inicio de sesión en AFIP" />
      <TextComponent>Descargue el certificado generado</TextComponent>
    </>
  );
}
