import { getCssClasses } from "../../../utils/cssUtils";
import classes from "./Spinner.module.css";

interface Props {
  full?: boolean;
  className?: string;
}

export default function Spinner({ full, className = "" }: Props) {
  const fullClass = `${full ? classes.fullScreen : ""}`;

  return (
    <div className={getCssClasses([fullClass, className])}>
      <div className={classes.spinner}>
        <div className={classes.dot1} />
        <div className={classes.dot2} />
      </div>
    </div>
  );
}
