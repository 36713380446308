import ImageComponent from "../../../components/Common/UI/Image/ImageComponent";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import stepOneImageOne from "../../../assets/wizard/step1_img1.png";

export default function OneLoginPage() {
  return (
    <>
      <TitleComponent title="1. Inicio de sesión" />
      <TextComponent>
        Ingrese al sitio de la AFIP con su clave. Debe poseer clave con Nivel 3
      </TextComponent>
      <ImageComponent src={stepOneImageOne} alt="Inicio de sesión en AFIP" />
    </>
  );
}
