import { TimelineItemStatus } from "../types/TimelineItemStatus";

export interface TimelineItem {
  status: TimelineItemStatus;
  title: string;
}

export const timelineItems: TimelineItem[] = [
  {
    status: TimelineItemStatus.IN_PROCESS,
    title: "Iniciar sesión",
  },
  {
    status: TimelineItemStatus.PENDING,
    title: "Administrador de relaciones",
  },
  {
    status: TimelineItemStatus.PENDING,
    title: "Certificados digitales",
  },
  {
    status: TimelineItemStatus.PENDING,

    title: "Descargar certificado",
  },
  {
    status: TimelineItemStatus.PENDING,
    title: "Agregar certificado",
  },
  {
    status: TimelineItemStatus.PENDING,
    title: "Subir certificado",
  },
  {
    status: TimelineItemStatus.PENDING,
    title: "Agregar punto de venta",
  },
  {
    status: TimelineItemStatus.PENDING,
    title: "Punto de venta",
  },
  {
    status: TimelineItemStatus.PENDING,
    title: "Verificar",
  },
];
