import { getCssClasses } from "../../../../utils/cssUtils";
import classes from "./LinkComponent.module.css";

interface LinkProps {
  to: string;
  text?: string;
  className?: string;
}

export default function LinkComponent(props: LinkProps) {
  const { className = "" } = props;

  return (
    <a
      target="_blank"
      className={getCssClasses([classes.link, className])}
      href={props.to}
      rel="noopener noreferrer"
    >
      {props.text || props.to}
    </a>
  );
}
