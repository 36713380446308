import { toast } from "react-toastify";
import i18n from "../i18n/i18n";
import { Texts } from "../i18n/Texts";

class UIService {
  toastError(message: string = i18n.translate(Texts.ERROR_SOMETHING_HAPPENED)) {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  toastSuccess(message: string) {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export default new UIService();
