import { useState } from "react";
import { useLocation } from "react-router";
import ButtonComponent from "../Common/Form/ButtonComponent";
import FormComponent from "../Common/Form/FormComponent";
import AuthOptions from "./AuthOptionsComponent";
import ReCaptchaComponent from "../Common/Captcha/ReCaptchaComponent";
import classes from "./AuthFormComponent.module.css";
import i18n from "../../i18n/i18n";
import { Texts } from "../../i18n/Texts";

interface Props {
  onSubmit: (captchaToken: string) => void;
  children: any;
  submitText: string;
}

export default function AuthFormComponent(props: Props) {
  const location = useLocation();

  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState<string>("");

  const onSubmitAuthForm = () => {
    if (!captchaToken) {
      setCaptchaError(i18n.translate(Texts.COMPLETE_CAPTCHA_TO_CONTINUE));
      return;
    }

    props.onSubmit(captchaToken);
  };

  const onChangeCaptcha = (tokenValue: string | null) => {
    setCaptchaToken(tokenValue);

    if (tokenValue) {
      setCaptchaError("");
    }
  };

  return (
    <FormComponent onSubmit={onSubmitAuthForm}>
      {props.children}
      <AuthOptions pathname={location.pathname} />
      <ReCaptchaComponent onChange={onChangeCaptcha} error={captchaError} />
      <ButtonComponent
        classNames={[classes.submitButton]}
        onClick={onSubmitAuthForm}
        text={props.submitText}
      />
    </FormComponent>
  );
}
