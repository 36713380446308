export enum Texts {
  LOG_IN,
  CREATE_ACCOUNT,
  REGISTER,
  CONFIRM_PASSWORD,
  ADD_EMAIL_TO_RECEIVE_CODE,
  COMPLETE_CAPTCHA_TO_CONTINUE,
  NO_ACCOUNT_YET,
  EMAIL,
  SEND,
  LOGIN,
  PASSWORD,
  PASSWORDS,
  SETTINGS_REQUIRED,
  SET_SETTINGS_BEFORE_CREATING_INVOICE,
  INVALID_CREDENTIALS,
  ACCOUNT_CREATED_SUCCESSFULLY,
  COULD_NOT_CREATE_ACCOUNT,
  DO_YOU_ALREADY_OWN_AN_ACCOUNT,
  DID_YOU_FORGET_PASSWORD,
  CONFIGURE_STEPS,
  MY_INVOICES,
  SETTINGS,
  INVOICE,
  INVOICE_NO,
  NO_INVOICES_FOR_NOW,
  OPTIONS,
  CONFIRM,
  CONCEPT,
  GO_BACK,
  DESCRIPTION,
  VAT_CONDITION,
  AMOUNT,
  TAX_ID,
  CUSTOMER_TAX_ID,
  CUSTOMER,
  INVOICE_NO_SHORT,
  BUSINESS_NAME,
  GENERATE_INVOICE,
  DATE,
  ADDRESS,
  NEXT_INVOICE_NUMBER,
  CURRENCY,
  PLACEHOLDER_DEFAULT_CONCEPT,
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_BUSINESS_NAME,
  PLACEHOLDER_ADDRESS,
  PLACEHOLDER_TAX_ID,
  CHANGE_PASSWORD,
  OLD_PASSWORD,
  NEW_PASSWORD,
  ERROR,
  ERROR_OCCURRED_LOGIN,
  ERROR_OCCURRED_TRY_AGAIN_LATER,
  ERROR_OCCURRED_UPDATING_SETTINGS,
  ERROR_PDF_NOT_VALID,
  TRY_AGAIN,
  ERROR_SOMETHING_HAPPENED,
  PAGE_NOT_FOUND,
  MYNVOICE_LOGO,
  PREVIOUS,
  NEXT,
  FINISH,
  LOGOUT,
  VALIDATION_MIN_LENGTH,
  VALIDATION_MAX_LENGTH,
  VALIDATION_STRONG_PASSWORD,
  VALIDATION_GREATER_THAN_DATE,
  VALIDATION_DATE_GREATER_TODAY,
  VALIDATION_PASSWORD_MATCH,
  VALIDATION_IS_EMAIL,
  VALIDATION_IS_REQUIRED,
}
