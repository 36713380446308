import AuthFormComponent from "../AuthFormComponent";
import useValidatedState from "../../../hooks/useValidatedState";
import useValidationGroup from "../../../hooks/useValidationGroup";
import {
  afterFirstChar,
  isEmail,
  isEqualTo,
  minLength,
} from "../../../utils/validators";
import InputComponent from "../../Common/Form/Input/InputComponent";
import userIcon from "../../../assets/login/ic_user.png";
import passwordIcon from "../../../assets/login/ic_pass.png";
import { useEffect, useRef } from "react";
import authService from "../../../services/AuthService";
import { useHistory } from "react-router";
import { LoginRoutes } from "../../../constants/routes";
import uiService from "../../../services/UIService";
import i18n from "../../../i18n/i18n";
import { Texts } from "../../../i18n/Texts";

export default function SignupComponent() {
  const passwordRef = useRef<string>("");
  const history = useHistory();

  const [validationGroup, formValid] = useValidationGroup();
  const [email, setEmail, emailError] = useValidatedState<string>(
    "",
    [isEmail()],
    afterFirstChar,
    validationGroup
  );

  const [password, setPassword, passwordError] = useValidatedState<string>(
    "",
    [minLength(i18n.translate(Texts.PASSWORD).toLowerCase())],
    afterFirstChar,
    validationGroup
  );

  const [
    confirmPassword,
    setConfirmPassword,
    confirmPasswordError,
  ] = useValidatedState<string>(
    "",
    [isEqualTo(passwordRef, i18n.translate(Texts.PASSWORDS).toLowerCase())],
    afterFirstChar,
    validationGroup
  );

  //Updates reference when password changes. Used to validate in real time
  useEffect(() => {
    passwordRef.current = password;
  }, [password]);

  const onSubmitHandler = async (captchaToken: string) => {
    if (!formValid()) {
      return;
    }

    try {
      await authService.signUp(email, password, confirmPassword, captchaToken);
      //TODO: Login automatically?
      history.push(LoginRoutes.LOGIN);
    } catch (error) {
      console.log("error on signup", error);
      uiService.toastError(i18n.translate(Texts.COULD_NOT_CREATE_ACCOUNT));
    }
  };

  return (
    <AuthFormComponent
      onSubmit={onSubmitHandler}
      submitText={i18n.translate(Texts.REGISTER)}
    >
      <InputComponent
        autoFocus
        value={email}
        setValue={setEmail}
        icon={userIcon}
        error={emailError}
        autoCapitalize="false"
        placeholder={i18n.translate(Texts.EMAIL)}
      />
      <InputComponent
        type="password"
        value={password}
        setValue={setPassword}
        icon={passwordIcon}
        error={passwordError}
        placeholder={i18n.translate(Texts.PASSWORD)}
      />
      <InputComponent
        type="password"
        value={confirmPassword}
        setValue={setConfirmPassword}
        icon={passwordIcon}
        error={confirmPasswordError}
        placeholder={i18n.translate(Texts.CONFIRM_PASSWORD)}
      />
    </AuthFormComponent>
  );
}
