import {
  SET_SALE_POINT_ROUTE_NUM,
  UPLOAD_FILE_ROUTE_NUM,
} from "../components/Wizard/WizardNavigatorComponent";
import { Settings } from "../models/Settings";
import { CsrResponse } from "../types/CsrResponse";
import { SalePointResponse } from "../types/SalePointResponse";
import { StorageTypes } from "../types/StorageTypes";
import { WizardConfig } from "../types/WizardConfig";
import { generateRequestId } from "../utils/stringUtils";
import AuthService from "./AuthService";
import { httpClient } from "./HttpClient";
import storageService from "./StorageService";

class UserService {
  private settings: Settings = Settings.getDefaultSettings();

  async fetchUserSettings(): Promise<Settings> {
    this.settings = await httpClient.get<Settings>("/user/settings");
    return this.settings;
  }

  getCsrCertificate(): Promise<CsrResponse> {
    return httpClient.get<CsrResponse>(
      `/ar/csrRequest?requestId=${generateRequestId()}`
    );
  }

  uploadCsrCertificate(file: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);

    return httpClient.postForm("/ar/certificateUpload", formData);
  }

  getSalePoints(): Promise<SalePointResponse> {
    return httpClient.get<SalePointResponse>(`/ar/salePoints`);
  }

  saveSellPoint(salePoint: number): Promise<void> {
    return httpClient.put(`/ar/salePoints`, {
      defaultSalePoint: salePoint,
    });
  }

  getWizardSavedState = (): WizardConfig => {
    return storageService.getItem<WizardConfig>(
      this.getUniqueKey(StorageTypes.LAST_WIZARD_SETTINGS),
      {
        currentRoute: 1,
        requiredRoutes: [UPLOAD_FILE_ROUTE_NUM, SET_SALE_POINT_ROUTE_NUM],
      } as WizardConfig
    );
  };

  setWizardSavedState = (wizardConfig: WizardConfig) => {
    storageService.setItem(
      this.getUniqueKey(StorageTypes.LAST_WIZARD_SETTINGS),
      wizardConfig
    );
  };

  private getUniqueKey = (storageType: StorageTypes) => {
    return `${storageType}-${AuthService.getUser()!.email}`;
  };
}

export default new UserService();
