import classes from "./TimelineComponent.module.css";
import { TimelineItemStatus } from "../../../../types/TimelineItemStatus";
import TimelineItemComponent from "./TimelineItemComponent";
import TitleComponent from "../Title/TitleComponent";
import { useEffect, useRef, useState } from "react";
import { TimelineItem, timelineItems } from "../../../../utils/timelineUtils";
import i18n from "../../../../i18n/i18n";
import { Texts } from "../../../../i18n/Texts";

interface Props {
  currentRoute: number;
}

export default function TimelineComponent(props: Props) {
  const [timeline, setTimeline] = useState<TimelineItem[]>(timelineItems);
  const prevRoute = useRef<number>(0);

  useEffect(() => {
    if (prevRoute.current !== props.currentRoute) {
      setTimeline((timeline) =>
        timeline.map((timelineItem, index) => {
          const routeIndex = props.currentRoute - 1;

          if (index < routeIndex) {
            return { ...timelineItem, status: TimelineItemStatus.DONE };
          }

          if (index === routeIndex) {
            return { ...timelineItem, status: TimelineItemStatus.IN_PROCESS };
          }

          return { ...timelineItem, status: TimelineItemStatus.PENDING };
        })
      );
    }

    prevRoute.current = props.currentRoute;
  }, [props.currentRoute]);

  return (
    <>
      <TitleComponent
        title={i18n.translate(Texts.CONFIGURE_STEPS)}
        classNames={[classes.title]}
      />
      <div className={classes.timelineContainer}>
        {timeline.map((timelineItem, index) => (
          <TimelineItemComponent
            status={timelineItem.status}
            key={timelineItem.title}
            title={timelineItem.title}
            stepNum={index + 1}
          />
        ))}
      </div>
    </>
  );
}
