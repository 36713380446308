import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";
import { environment } from "../../../config/environment";
import ErrorMessageComponent from "../Form/ErrorMessageComponent";
import classes from "./ReCaptchaComponent.module.css";

type Props = {
  error: string;
  onChange: ReCAPTCHAProps["onChange"];
};

export default function ReCaptchaComponent(props: Props) {
  const { error, ...reCaptchaProps } = props;

  return (
    <div className={classes.captchaContainer}>
      {error && <ErrorMessageComponent text={error} />}

      <ReCAPTCHA
        onChange={props.onChange}
        sitekey={environment.GOOGLE_RECAPTCHA_URL}
        {...reCaptchaProps}
      />
    </div>
  );
}
