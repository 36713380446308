import AuthFormComponent from "../AuthFormComponent";
import useValidatedState from "../../../hooks/useValidatedState";
import useValidationGroup from "../../../hooks/useValidationGroup";
import { afterFirstChar, isEmail, minLength } from "../../../utils/validators";
import InputComponent from "../../Common/Form/Input/InputComponent";
import userIcon from "../../../assets/login/ic_user.png";
import passwordIcon from "../../../assets/login/ic_pass.png";
import authService from "../../../services/AuthService";
import Spinner from "../../Common/UI/Spinner";
import { useState } from "react";
import uiService from "../../../services/UIService";
import i18n from "../../../i18n/i18n";
import { Texts } from "../../../i18n/Texts";
import { ErrorCodes } from "../../../types/exceptions/ErrorCodes";

export default function LoginComponent() {
  const [validationGroup, valid] = useValidationGroup();
  const [loading, setLoading] = useState<boolean>(false);

  const [email, setEmail, emailError] = useValidatedState<string>(
    "",
    [isEmail()],
    afterFirstChar,
    validationGroup
  );

  const [password, setPassword, passwordError] = useValidatedState<string>(
    "",
    [minLength(i18n.translate(Texts.PASSWORD).toLowerCase())],
    afterFirstChar,
    validationGroup
  );

  const onSubmitHandler = async (captchaToken: string) => {
    if (!valid()) {
      return;
    }

    setLoading(true);

    try {
      await authService.login(email, password, captchaToken);
    } catch (error) {
      console.log("Could not login", error);

      let message = i18n.translate(Texts.ERROR_OCCURRED_LOGIN);
      if (error.message === ErrorCodes.INVALID_CREDENTIALS) {
        message = i18n.translate(Texts.INVALID_CREDENTIALS);
      }

      uiService.toastError(message);
    }

    setLoading(false);
  };

  if (loading) {
    return <Spinner full />;
  }

  return (
    <AuthFormComponent
      onSubmit={onSubmitHandler}
      submitText={i18n.translate(Texts.LOGIN)}
    >
      <InputComponent
        autoFocus
        value={email}
        setValue={setEmail}
        icon={userIcon}
        error={emailError}
        placeholder={i18n.translate(Texts.EMAIL)}
      />
      <InputComponent
        type="password"
        value={password}
        setValue={setPassword}
        icon={passwordIcon}
        error={passwordError}
        placeholder={i18n.translate(Texts.PASSWORD)}
      />
    </AuthFormComponent>
  );
}
