import { MemoryRouter, Route, Switch } from "react-router";
import LoginComponent from "../../components/Auth/Login/LoginComponent";
import RecoverPasswordComponent from "../../components/Auth/RecoverPassword/RecoverPasswordComponent";
import SignupComponent from "../../components/Auth/Signup/SignupComponent";
import { LoginRoutes } from "../../constants/routes";
import classes from "./AuthenticationPage.module.css";
import MynvoiceLogoComponent from "../../components/Common/UI/MynvoiceLogoComponent";

export default function AuthenticationPage() {
  return (
    <main className={classes.authPageContainer}>
      <div className={classes.backgroundImage} />
      <section className={classes.authContainer}>
        <MynvoiceLogoComponent />
        <MemoryRouter>
          <Switch>
            <Route path={LoginRoutes.SIGNUP}>
              <SignupComponent />
            </Route>
            <Route path={LoginRoutes.RECOVER_PASSWORD}>
              <RecoverPasswordComponent />
            </Route>
            <Route path={["*", LoginRoutes.LOGIN]}>
              <LoginComponent />
            </Route>
          </Switch>
        </MemoryRouter>
      </section>
    </main>
  );
}
