/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {ValidationGroup, ValidationItem} from './types/ValidationGroup';
import {Validator} from './types/Validator';

/**
 * @param initialValue Start value
 * @param validators Array of validators
 * @param touched function that determines whether the validation should start or not
 * @param setValidationGroup function that comes from useValidationGroup that ensures all fields are valid
 *
 * @returns [value, setValue, errorMessage]
 *
 * value: The current value of the field
 *
 * setValue: the setter for the value of the field
 *
 * errorMessage: string representing the error message if present or empty if not present
 */
export default function useValidatedState<T>(
  initialValue: T,
  validators: Validator<T>[],
  touched: (value: T) => boolean,
  setValidationGroup: Dispatch<SetStateAction<ValidationGroup>>,
): [T, Dispatch<SetStateAction<T>>, string] {
  const [errorMessage, setErrorMessage] = useState('');
  const [pristine, setPristine] = useState(true);
  const [value, setValue] = useState<T>(initialValue);

  // Save validators in a reference so the value won't trigger a re-render
  const innerValidators = useRef(validators);

  // To simulate the componentDidMount effect
  const didRun = useRef(false);

  /** Save a reference to the validator function, it's needed because if a
   * function without a reference is stored the validator would be outdated
   * at the moment it is being called.
   */
  const validatorFunction = useRef() as ValidationItem;

  /**
   * Forces the validation to be ran. Useful for instance when the state
   * is still untouched because the underlying component was not touched
   * at all but we still need to force the validation, for instance when
   * a "submit" button was triggered
   */
  const runValidations = useCallback(
    (pristine: boolean) => {
      // if pristine but touched condition have been reached, the state is
      // no longer pristine
      if (touched(value) && pristine) {
        setPristine(false);
      }

      // runs all the validators, collects all the errors and, if one or more,
      // keeps and sets the first one
      const errorList: string[] = innerValidators.current
        .map((validator) => {
          return validator(value);
        })
        .filter((v) => v.length > 0);
      const error: string =
        !pristine && errorList.length > 0 ? errorList[0] : '';

      setErrorMessage(error);
      return error;
    },
    [value, touched],
  );

  useEffect(() => {
    // Updates validator function
    // validator function needs to be recreated to update the values)
    validatorFunction.current = () => {
      // forces running a validation and rollbacks the pristine state if set
      setPristine(false);
      return runValidations(false);
    };

    //Validates after every change in the field
    runValidations(pristine);
  }, [runValidations, pristine]);

  useEffect(() => {
    //Ensures it's being called just once (simulating componentDidMount)
    if (didRun.current) {
      return;
    }

    //This uses setValidationGroup provided by useValidationGroup to add this field
    //to the validator group
    setValidationGroup((prevState) => [...prevState, validatorFunction]);

    didRun.current = true;
  }, [runValidations, setValidationGroup, value]);

  return [value, setValue, errorMessage];
}
