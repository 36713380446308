import { SetStateAction } from "react";
import ErrorMessageComponent from "../ErrorMessageComponent";
import classes from "./InputComponent.module.css";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  setValue: React.Dispatch<SetStateAction<string>>;
  value: string;
  icon: any;
  error: string;
};

export default function InputComponent(props: Props) {
  const { setValue, value, icon, error, ...inputProps } = props;

  return (
    <>
      <div className={classes.inputContainer}>
        {icon && <img className={classes.inputIcon} src={icon} alt="icon" />}
        <input
          className={classes.input}
          type={props.type || "text"}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          {...inputProps}
        />
      </div>
      <ErrorMessageComponent text={error} />
    </>
  );
}
