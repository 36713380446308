import classes from "./MainLayoutComponent.module.css";
import PageContainerComponent from "./PageContainerComponent";
import SideBarComponent from "./SideBarComponent";

interface Props {
  children: any;
}

export default function MainLayoutComponent(props: Props) {
  return (
    <div className={classes.mainLayout}>
      <SideBarComponent />
      <PageContainerComponent>{props.children}</PageContainerComponent>
    </div>
  );
}
