import classes from "./TextComponent.module.css";

interface Props {
  children: string | string[];
  inline?: boolean;
}

export default function TextComponent(props: Props) {
  return (
    <p
      style={{ display: props.inline ? "inline" : "block" }}
      className={classes.text}
    >
      {props.children}
    </p>
  );
}
