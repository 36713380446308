import classes from "./SevenAddElectronicSalePoint.module.css";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import LinkComponent from "../../../components/Common/UI/Link/LinkComponent";
import ImageComponent from "../../../components/Common/UI/Image/ImageComponent";
import stepSevenImageOne from "../../../assets/wizard/step7_img1.png";
import stepSevenImageTwo from "../../../assets/wizard/step7_img2.png";

interface Props {}

export default function SevenAddElectronicSalePoint(props: Props) {
  return (
    <>
      <TitleComponent title="7. Agregar punto de venta electrónico" />
      <TextComponent inline>Ingrese a </TextComponent>
      <LinkComponent
        className={classes.link}
        text="Puntos de venta"
        to="https://serviciosjava2.afip.gob.ar/pvel/jsp/index_bis.jsp"
      />
      <TextComponent>Seleccione su CUIT (si aparece mas de uno)</TextComponent>
      <ImageComponent src={stepSevenImageOne} alt="Puntos de venta" />
      <TextComponent>Haga click en agregar:</TextComponent>
      <ImageComponent src={stepSevenImageTwo} alt="Puntos de venta" />
    </>
  );
}
