import { TimelineItemStatus } from "../../../../types/TimelineItemStatus";
import {
  getClassNameByTimelineItemStatus,
  getCssClasses,
} from "../../../../utils/cssUtils";
import classes from "./TimelineItemComponent.module.css";
import checkOn from "../../../../assets/wizard/ic_check_on.png";

interface Props {
  status: TimelineItemStatus;
  title: string;
  stepNum: number;
}

export default function TimelineItemComponent({
  stepNum,
  status,
  title,
}: Props) {
  const statusClassName = getClassNameByTimelineItemStatus(status);
  const statusClass = classes[statusClassName];

  const timelineItemContainerClasses = [
    classes.timelineItemContainer,
    statusClass,
  ];
  const timelineItemClasses = [classes.timelineItem, statusClass];
  const timelineTextClasses = [classes.timelineItemText, statusClass];

  return (
    <div className={getCssClasses(timelineItemContainerClasses)}>
      <div className={getCssClasses(timelineItemClasses)} />
      <img className={classes.checkOn} src={checkOn} alt="" />
      <span>{stepNum}</span>
      <div className={getCssClasses(timelineTextClasses)}>
        <h3>{title}</h3>
      </div>
    </div>
  );
}
