import { Link } from "react-router-dom";
import { navigationMenuList, NavItem } from "../../../../constants/navigation";
import IconComponent from "../IconComponent";
import MynvoiceLogoComponent from "../MynvoiceLogoComponent";
import classes from "./SideBarComponent.module.css";

export default function SideBarComponent() {
  return (
    <div className={classes.sideBar}>
      <MynvoiceLogoComponent />
      <NavigationListComponent />
    </div>
  );
}

const NavigationListComponent = () => (
  <div className={classes.navigationMenu}>
    {navigationMenuList.map((navItem, index) => (
      <NavigationItem key={index} navItem={navItem} />
    ))}
  </div>
);

const NavigationItem = ({ navItem }: { navItem: NavItem }) => {
  let menuItem;

  if (navItem.action) {
    menuItem = <button onClick={navItem.action}>{navItem.name}</button>;
  }

  if (navItem.to) {
    menuItem = <Link to={navItem.to}>{navItem.name}</Link>;
  }

  return (
    <div key={navItem.to} className={classes.navItemContainer}>
      {navItem.icon && <IconComponent icon={navItem.icon} alt={navItem.name} />}
      {menuItem}
    </div>
  );
};
