import { MouseEventHandler } from "react";
import { getCssClasses } from "../../../utils/cssUtils";
import classes from "./ButtonComponent.module.css";

interface Props {
  onClick: () => void;
  text: string;
  classNames?: string[];
  icon?: {
    source: string;
    position: "left" | "right";
  };
  disabled?: boolean;
}

export default function ButtonComponent(props: Props) {
  const { onClick, text, icon, classNames = [], disabled = false } = props;

  const onClickHandler: MouseEventHandler<any> = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      className={getCssClasses([classes.button, ...classNames])}
      type="submit"
      onClick={onClickHandler}
      disabled={disabled}
    >
      <span className={classes.buttonContent}>
        {icon && icon.position === "left" && (
          <img
            className={getCssClasses([classes.icon, classes.left])}
            src={icon.source}
            alt=""
          />
        )}
        {text}
        {icon && icon.position === "right" && (
          <img
            className={getCssClasses([classes.icon, classes.right])}
            src={icon.source}
            alt=""
          />
        )}
      </span>
    </button>
  );
}
