import { useEffect, useState } from "react";
import { MemoryRouter } from "react-router-dom";
import SectionBoxComponent from "../../components/Common/UI/Layout/SectionBoxComponent";
import TimelineComponent from "../../components/Common/UI/Timeline/TimelineComponent";
import WizardNavigatorComponent from "../../components/Wizard/WizardNavigatorComponent";
import { Settings } from "../../models/Settings";
import userService from "../../services/UserService";
import { WizardConfig } from "../../types/WizardConfig";

export default function WizardPage() {
  const [userSettings, setUserSettings] = useState<Settings>(
    Settings.getDefaultSettings()
  );
  const [savedState, setSavedState] = useState<WizardConfig>(
    userService.getWizardSavedState()
  );

  //Updates lastVisitedRoute when route changes
  useEffect(() => {
    userService.setWizardSavedState(savedState);
  }, [savedState]);

  useEffect(() => {
    const getSettings = async () => {
      const settings = await userService.fetchUserSettings();
      setUserSettings(settings);
    };

    getSettings();
  }, []);

  return (
    <>
      <TimelineComponent currentRoute={savedState.currentRoute} />
      <SectionBoxComponent>
        <MemoryRouter>
          <WizardNavigatorComponent
            savedState={savedState}
            setSavedState={setSavedState}
            userSettings={userSettings}
          />
        </MemoryRouter>
      </SectionBoxComponent>
    </>
  );
}
