import passwordChangeIcon from "../assets/common/ic_password_change.png";
import logoutIcon from "../assets/common/ic_logout.png";
import authService from "../services/AuthService";
import { AppRoutes } from "./routes";
import i18n from "../i18n/i18n";
import { Texts } from "../i18n/Texts";

// To add a new route, either add to or action
// 'to' will trigger an internal navigation, and action a function
export type NavItem = {
  to?: AppRoutes;
  action?: () => void;
  name: string;
  icon?: string;
};

export const navigationMenuList: NavItem[] = [
  {
    to: AppRoutes.CHANGE_PASSWORD,
    name: i18n.translate(Texts.CHANGE_PASSWORD),
    icon: passwordChangeIcon,
  },
  {
    action: () => authService.logout(),
    name: i18n.translate(Texts.LOGOUT),
    icon: logoutIcon,
  },
];
