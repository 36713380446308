import { getCssClasses } from "../../../utils/cssUtils";
import classes from "./FormComponent.module.css";

interface Props {
  onSubmit: () => void;
  children: JSX.Element | JSX.Element[];
  classNames?: string[];
}
export default function FormComponent(props: Props) {
  const { children, classNames = [], onSubmit } = props;

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form
      className={getCssClasses([classes.form, ...classNames])}
      onSubmit={onSubmitHandler}
    >
      {children}
    </form>
  );
}
