import React, { SetStateAction, useEffect, useState } from "react";
import Select from "react-select";
import ButtonComponent from "../../../components/Common/Form/ButtonComponent";
import Spinner from "../../../components/Common/UI/Spinner";
import TextComponent from "../../../components/Common/UI/Text/TextComponent";
import TitleComponent from "../../../components/Common/UI/Title/TitleComponent";
import Logger from "../../../services/Logger";
import UIService from "../../../services/UIService";
import userService from "../../../services/UserService";
import classes from "./NineVerificationPage.module.css";

const logger = new Logger(NineVerificationPage.name);

interface SalePointOption {
  value: string;
  label: string;
}

interface Props {
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  onSetNextPageEnabled: () => void;
}

export default function NineVerificationPage(props: Props) {
  const { loading, setLoading, onSetNextPageEnabled } = props;

  const [salePoints, setSalePoints] = useState<SalePointOption[]>([]);
  const [
    selectedSalePoint,
    setSelectedSellpoint,
  ] = useState<SalePointOption | null>(null);

  useEffect(() => {
    const fetchSalePoints = async () => {
      setLoading(true);

      try {
        const userSalePoints = await userService.getSalePoints();
        const options = userSalePoints.salePoints.map((salePoint) => ({
          label: salePoint.emisionTipo,
          value: salePoint.nro + "",
        }));

        if (options.length === 1) {
          setSelectedSellpoint(options[0]);
          onSetNextPageEnabled();
        }

        setSalePoints(options);
      } catch (error) {
        logger.error("Could not get sell points", error);
        UIService.toastError(
          "No se pudo obtener puntos de venta. Por favor, inténtalo más tarde"
        );
      }

      setLoading(false);
    };

    fetchSalePoints();
    //eslint-disable-next-line
  }, [setLoading]);

  const onSelectInputChange = (selectedOpt: any) => {
    setSelectedSellpoint(selectedOpt);
  };

  const onClickConfirmSalePoint = async () => {
    if (!selectedSalePoint) {
      return;
    }

    setLoading(true);

    try {
      await userService.saveSellPoint(parseInt(selectedSalePoint.value));
      onSetNextPageEnabled();

      UIService.toastSuccess(
        "Has seleccionado un punto de venta satisfactoriamente"
      );
    } catch (error) {
      UIService.toastError(
        "No se pudo guardar el punto de venta. Por favor, inténtalo más tarde"
      );
    }

    setLoading(false);
  };

  return (
    <>
      <TitleComponent title="9. Verificar" />
      <TextComponent>
        Seleccione el punto de venta por defecto para factura electronica del
        siguiente listado:
      </TextComponent>
      {loading ? (
        <Spinner />
      ) : (
        <Select
          value={selectedSalePoint}
          onChange={onSelectInputChange}
          placeholder="Seleccionar punto de venta"
          className={classes.select}
          options={salePoints}
          styles={{
            control: (provided) => ({
              ...provided,
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
            }),
            menu: (provided) => ({ ...provided, fontSize: 14 }),
            placeholder: (provided) => ({ ...provided, fontSize: 14 }),
            indicatorSeparator: () => ({ display: "none" }),
          }}
        />
      )}
      <div className={classes.buttonContainer}>
        <ButtonComponent
          classNames={[classes.button, classes.right]}
          onClick={onClickConfirmSalePoint}
          text="Confirmar"
          disabled={!selectedSalePoint}
        />
      </div>
    </>
  );
}
