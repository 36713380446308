import AppNavigation from "./navigation/AppNavigation";
import { BrowserRouter as Router } from "react-router-dom";
import MainLayoutComponent from "./components/Common/UI/Layout/MainLayoutComponent";
import { useEffect, useState } from "react";
import authService, { AuthServiceEvents } from "./services/AuthService";
import AuthenticationPage from "./pages/Auth/AuthenticationPage";
import Spinner from "./components/Common/UI/Spinner";

function AppContainer() {
  const [logged, setLogged] = useState<boolean | null>(null);

  useEffect(() => {
    const updateAuthStatus = (logged: boolean) => {
      setLogged(logged);
    };

    authService.addObserver(
      AuthServiceEvents.AUTH_STATE_CHANGED,
      updateAuthStatus
    );

    authService.checkIfLogged();

    return () => {
      authService.removeObserver(
        AuthServiceEvents.AUTH_STATE_CHANGED,
        updateAuthStatus
      );
    };
  }, []);

  if (logged === null) {
    return <Spinner full />;
  }

  if (logged === false) {
    return <AuthenticationPage />;
  }

  return (
    <Router>
      <MainLayoutComponent>
        <AppNavigation />
      </MainLayoutComponent>
    </Router>
  );
}

export default AppContainer;
