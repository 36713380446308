import axios, { AxiosInstance, AxiosResponse } from "axios";
import { environment } from "../config/environment";
import InvalidResponseError from "../types/exceptions/InvalidResponseError";
import Logger from "./Logger";
import { HttpMethods } from "../types/HttpMethods";
import { MynvoiceAuthentication } from "../types/MynvoiceAuthentication";

class HttpClient {
  private logger = new Logger(HttpClient.name);
  private authentication: MynvoiceAuthentication = { email: "", authToken: "" };
  private backendUrl: string;
  private axios: AxiosInstance;

  constructor(hostBackend: string) {
    this.backendUrl = hostBackend;

    this.axios = axios.create({
      baseURL: hostBackend,
    });

    this.axios.interceptors.request.use(
      (config) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authentication: this.authentication.authToken,
            email: this.authentication.email,
          },
        };
      },
      (error) => Promise.reject(error)
    );
  }

  public setAuthentication(authentication: MynvoiceAuthentication) {
    this.authentication = authentication;
  }

  public get<T>(endpoint: string): Promise<T> {
    return this.doRequest<T>(
      () => this.axios.get(endpoint),
      endpoint,
      HttpMethods.GET
    );
  }

  public post<T>(endpoint: string, bodyPayload: object): Promise<T> {
    return this.doRequest<T>(
      () => this.axios.post(endpoint, bodyPayload),
      endpoint,
      HttpMethods.POST
    );
  }

  public put<T>(endpoint: string, bodyPayload: object): Promise<T> {
    return this.doRequest<T>(
      () => this.axios.put(endpoint, bodyPayload),
      endpoint,
      HttpMethods.PUT
    );
  }

  public patch<T>(endpoint: string, bodyPayload: object): Promise<T> {
    return this.doRequest<T>(
      () => this.axios.patch(endpoint, bodyPayload),
      endpoint,
      HttpMethods.PATCH
    );
  }

  public delete<T>(endpoint: string): Promise<T> {
    return this.doRequest<T>(
      () => this.axios.delete(endpoint),
      endpoint,
      HttpMethods.DELETE
    );
  }

  /** Post multipart/form-data */
  public postForm<T>(endpoint: string, bodyPayload: FormData): Promise<T> {
    return this.doRequest<T>(
      () =>
        this.axios.post(endpoint, bodyPayload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
      endpoint,
      HttpMethods.POST
    );
  }

  /** Put multipart/form-data */
  public putForm<T>(endpoint: string, bodyPayload: FormData): Promise<T> {
    return this.doRequest<T>(
      () =>
        this.axios.put(endpoint, bodyPayload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
      endpoint,
      HttpMethods.PUT
    );
  }

  private async doRequest<T>(
    requestGet: () => Promise<AxiosResponse<T>>,
    endpoint: string,
    httpMethod: HttpMethods
  ) {
    try {
      this.logRequest(endpoint, httpMethod);
      const response = await requestGet();
      this.logResponse(endpoint, httpMethod);

      return response.data;
    } catch (error) {
      throw new InvalidResponseError(
        `[HttpClient][${httpMethod}][${this.getFullUrl(endpoint)}] ` +
          `Response status was ${error?.response?.status}`,
        error.response
      );
    }
  }

  private getFullUrl(endpoint: string): string {
    return this.backendUrl + endpoint;
  }

  private logRequest(endpoint: string, method: HttpMethods) {
    this.logger.info(
      `HTTP ${method} Request for the path:  ${this.getFullUrl(endpoint)} \n`
    );
  }

  private logResponse(endpoint: string, method: HttpMethods) {
    this.logger.info(
      `HTTP ${method} Response from the path: ${this.getFullUrl(endpoint)} \n`
    );
  }
}

export const httpClient = new HttpClient(environment.API_URL);
