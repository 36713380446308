import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import classes from "./WizardNavigatorComponent.module.css";
import ButtonComponent from "../Common/Form/ButtonComponent";
import { WizardRoutes } from "../../constants/routes";
import { Route, Switch, useHistory } from "react-router";
import prevIcon from "../../assets/wizard/ic_prev.png";
import nextIcon from "../../assets/wizard/ic_next.png";
import { Settings } from "../../models/Settings";
import OneLoginPage from "../../pages/Wizard/Internal/OneLoginPage";
import TwoRelationshipManagerPage from "../../pages/Wizard/Internal/TwoRelationshipManagerPage";
import ThreeDigitalCertificatePage from "../../pages/Wizard/Internal/ThreeDigitalCertificatePage";
import FourDownloadCertificatePage from "../../pages/Wizard/Internal/FourDownloadCertificatePage";
import FiveAddCertificatePage from "../../pages/Wizard/Internal/FiveAddCertificatePage";
import SixUploadCertificatePage from "../../pages/Wizard/Internal/SixUploadCertificatePage";
import SevenAddElectronicSalePoint from "../../pages/Wizard/Internal/SevenAddElectronicSalePoint";
import EightLinkCertificatePage from "../../pages/Wizard/Internal/EightLinkCertificatePage";
import NineVerificationPage from "../../pages/Wizard/Internal/NineVerificationPage";
import ConfigurationCompletedPage from "../../pages/Wizard/Internal/ConfigurationCompletedPage";
import { WizardConfig } from "../../types/WizardConfig";
import i18n from "../../i18n/i18n";
import { Texts } from "../../i18n/Texts";

const maxPageLength = Object.keys(WizardRoutes).length / 2;

interface Props {
  savedState: WizardConfig;
  setSavedState: React.Dispatch<SetStateAction<WizardConfig>>;
  userSettings: Settings;
}

export const UPLOAD_FILE_ROUTE_NUM = 6;
export const SET_SALE_POINT_ROUTE_NUM = 9;

export default function WizardNavigatorComponent(props: Props) {
  const history = useHistory();

  const { savedState, setSavedState } = props;
  const { currentRoute, requiredRoutes } = savedState;

  const [file, setFile] = useState<File | null>(null);

  //Check if next page should be enabled
  const [nextPageEnabled, setNextPageEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    history.push(`/step-${currentRoute}`);
    window.scrollTo(0, 0);

    //This is ok, it needs to be executed just once
    //eslint-disable-next-line
  }, [history, currentRoute]);

  const handlePageChange = useCallback(
    (action: "forward" | "backwards" | "finish") => {
      let nextRoute: number | null = null;

      if (action === "forward" && currentRoute < maxPageLength) {
        if (!nextPageEnabled) {
          return;
        }

        nextRoute = currentRoute + 1;
      }

      if (action === "backwards" && currentRoute > 1) {
        nextRoute = currentRoute - 1;
      }

      if (nextRoute !== null) {
        setNextPageEnabled(!requiredRoutes.includes(nextRoute));
        setSavedState((prev) => ({ ...prev, currentRoute: nextRoute! }));
      }
    },
    [nextPageEnabled, currentRoute, setSavedState, requiredRoutes]
  );

  //Navigation with arrow keys
  useEffect(() => {
    const selectArrow = ({ keyCode }: any) => {
      if ([39, 37].includes(keyCode)) {
        handlePageChange(keyCode === 39 ? "forward" : "backwards");
      }
    };
    document.addEventListener("keydown", selectArrow);

    return () => {
      document.removeEventListener("keydown", selectArrow);
    };
  }, [handlePageChange]);

  const { taxId } = props.userSettings;

  const onSetNextPageEnabled = (routeNum: number) => {
    setNextPageEnabled(true);
    setSavedState((prev) => ({
      ...prev,
      requiredRoutes: prev.requiredRoutes.filter((route) => route !== routeNum),
    }));
  };

  return (
    <div className={classes.wizardStepContainer}>
      <div className={classes.wizardPage}>
        <Switch>
          <Route path="/step-1">
            <OneLoginPage />
          </Route>

          <Route path="/step-2">
            <TwoRelationshipManagerPage />
          </Route>

          <Route path="/step-3">
            <ThreeDigitalCertificatePage taxId={taxId} />
          </Route>

          <Route path="/step-4">
            <FourDownloadCertificatePage />
          </Route>

          <Route path="/step-5">
            <FiveAddCertificatePage />
          </Route>

          <Route path="/step-6">
            <SixUploadCertificatePage
              file={file}
              setFile={setFile}
              setLoading={setLoading}
              loading={loading}
              onFileUpload={() => onSetNextPageEnabled(UPLOAD_FILE_ROUTE_NUM)}
            />
          </Route>

          <Route path="/step-7">
            <SevenAddElectronicSalePoint />
          </Route>

          <Route path="/step-8">
            <EightLinkCertificatePage taxId={taxId} />
          </Route>

          <Route path="/step-9">
            <NineVerificationPage
              loading={loading}
              setLoading={setLoading}
              onSetNextPageEnabled={() =>
                onSetNextPageEnabled(SET_SALE_POINT_ROUTE_NUM)
              }
            />
          </Route>

          <Route path="/step-10">
            <ConfigurationCompletedPage />
          </Route>
        </Switch>
      </div>

      <div className={classes.bottomButtonsContainer}>
        {currentRoute > 1 && (
          <ButtonComponent
            classNames={[classes.button, classes.left]}
            onClick={() => handlePageChange("backwards")}
            text={i18n.translate(Texts.PREVIOUS)}
            icon={{ source: prevIcon, position: "left" }}
          />
        )}

        {currentRoute < maxPageLength - 1 && (
          <ButtonComponent
            classNames={[classes.button, classes.right]}
            onClick={() => handlePageChange("forward")}
            text={i18n.translate(Texts.NEXT)}
            icon={{ source: nextIcon, position: "right" }}
            disabled={!nextPageEnabled}
          />
        )}

        {currentRoute === maxPageLength - 1 && (
          <ButtonComponent
            classNames={[classes.button, classes.right, classes.green]}
            onClick={() => handlePageChange("forward")}
            text={i18n.translate(Texts.FINISH)}
            disabled={!nextPageEnabled}
          />
        )}
      </div>
    </div>
  );
}
